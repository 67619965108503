import React from 'react';
import {Decimal} from 'decimal.js';

import DataDriver from 'library/DataDriver';
import Utils from 'library/Utils';

import './style.scss';

import PriceLabel from 'components/PriceLabel';
import Checkbox from 'components/Checkbox';

// Can be run independently, or with lifted state
class PricingTable extends React.Component {
    constructor(props) {
        super(props);

        if (!props.module){
            throw new Error('Charge key must be set');
        }

        this.state = {"DD:prices": [], "DD:taxRate": 0.2};

        this.onInput = this.onInput.bind(this);
        this.onCheck = this.onCheck.bind(this);
    }

    static defaultProps = {
        includeTax: true       
    }

    componentDidMount(){
        this.listenerID = DataDriver.attachListenerForStateChange(this, ["prices", "taxRate"]);
    }

    componentWillUnmount() {
        DataDriver.removeListener(this.listenerID);
    }

    onInput(e){
        if (!Utils.isNumeric(e.target.value)){
            return;
        }

        if (this.props.qtyChange){
            this.props.qtyChange(e.target.name, e.target.value)
        }else{
            let setState = {};
            setState[e.target.name] = e.target.value;
            this.setState(setState);
        }
    }

    onCheck(e){
        let val;
        if (e.target.checked){
            val = 1;
        }else{
            val = 0;
        }

        if (this.props.qtyChange){
            this.props.qtyChange(e.target.name, val)
        }else{
            let setState = {};
            setState[e.target.name] = val;
            this.setState(setState);
        }
    }

    render() {
        // Get charge keys for module
        let chargeKeys;
        switch (this.props.module) {
            case "General":
                chargeKeys = ["FileDownloadAmount", "Support"];
                break;

            case "Add-ons":
                chargeKeys = ["CustomIdent", "FilesScanned", "Sage200UK"];
                break;
        
            default:
                chargeKeys = [];
                for (const chargeKey in this.state["DD:prices"]) {
                    if (this.state["DD:prices"].hasOwnProperty(chargeKey)) {
                        if (this.state["DD:prices"][chargeKey].Module === this.props.module){
                            chargeKeys.push(chargeKey);
                        }
                    }
                }
                break;
        }

        // Add passed in defaults if set
        let values = this.state;
        if (this.props.chargeKeyQts){
            for (const chargeKey in this.props.chargeKeyQts) {
                if (this.props.chargeKeyQts.hasOwnProperty(chargeKey) && chargeKeys.indexOf(chargeKey) !== -1) {
                    values[chargeKey] = this.props.chargeKeyQts[chargeKey];
                }
            }
        }

        const renderInput = function(chargeKey, value){
            switch (this.state["DD:prices"][chargeKey].PriceModel) {
                case "PerPeriod":
                case "PercOnTotal":
                    return <Checkbox 
                                name={chargeKey}
                                isChecked={(value === 1)}
                                changeHandler={this.onCheck}
                            />
            
                default:
                    return <div className="PMQtyInput">
                                <input name={chargeKey} value={value} onChange={this.onInput}></input>
                            </div>
            }
        }.bind(this);

        // Form array
        let sects = [];
        let total = new Decimal(0);
        for (let i = 0; i < chargeKeys.length; i++) {
            const chargeKey = chargeKeys[i];

            if (!this.state["DD:prices"].hasOwnProperty(chargeKey)){
                break;
            }

            let value = 0
            if (values.hasOwnProperty(chargeKey)){
                value = values[chargeKey];
            }

            let title = this.state["DD:prices"][chargeKey].Title;

            let subTotal;
            if (this.state["DD:prices"][chargeKey].PriceModel == "PercOnTotal"){
                // Percentage addition to total usage
                if (Utils.isNumeric(value)){
                    if (this.props.usageTotal){
                        subTotal = this.props.usageTotal.mul(this.state["DD:prices"][chargeKey].currentPrice).div(100).mul(value);

                        total = total.plus(subTotal.div(30));
                        
                        subTotal = "£" + subTotal.toFixed(2) + " per month";
                    }else{
                        subTotal = null;
                    }
                }else{
                    subTotal = "£0.00 per month";
                }

            }else{
                // Normal; unit price * quantity
                if (Utils.isNumeric(value)){
                    let unitPrice = new Decimal(this.state["DD:prices"][chargeKey].currentPrice);
                    subTotal = unitPrice.mul(value);

                    if (this.props.includeTax){
                        subTotal = subTotal.add(subTotal.mul(this.state["DD:taxRate"]));
                    }

                    total = total.plus(subTotal);

                    subTotal = "£" + subTotal.toFixed(2);

                }else{
                    value = 0;
                    subTotal = "£0.00";
                }

            }

            let perString = "per day";
            if (['Support'].includes(chargeKey)){
                perString = "per month";
            }

            sects.push(
                <div className='priceMetricSection' key={chargeKey}>
                    <h4>{title}</h4>
                    <div className='priceMeticCalcRow'>
                        <div className='PMPriceLabel'>
                            <PriceLabel chargeKey={chargeKey} includeTax={this.props.includeTax}></PriceLabel>
                        </div>
                        <div className="priceMeticCalc">
                            {renderInput(chargeKey, value)}
                            {subTotal !== null && 
                                <div className="PMSubTotal">
                                    {this.state["DD:prices"][chargeKey].PriceModel === "PerUnit" ? (
                                        <p>{perString} = {subTotal}</p>
                                    ) : (
                                        <p>= {subTotal}</p>
                                    )}
                                </div>
                            }
                        </div>
                    </div>
                    <div className='PMDescr'>
                        <p>{this.state["DD:prices"][chargeKey].Descr}</p>
                    </div>
                </div>
            );
        }

        return (
            <>
                {sects}
                <div className="priceTableSubTotalWrap">
                    <div className="priceTableSubTotal">
                        <span>
                            <p className="PTSTTitle">Sub total:</p>
                            <p className="PTSTValue">£{total.toFixed(2)} / day</p>
                        </span>
                        {this.props.includeTax ? (
                            <p className="PTSTTaxNotice">Including VAT</p>
                        ) : (
                            <p className="PTSTTaxNotice">Excl. VAT</p>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

export default PricingTable;

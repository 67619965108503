import React from 'react';
import Decimal from 'decimal.js';

import DataDriver from 'library/DataDriver';
import Utils from 'library/Utils';
import ViewTrack from 'library/ViewTrack';

import PageWrap from 'components/PageWrap';
import { Section } from 'components/Section';

import './calculator.scss';

import { BorderedBox } from 'components/BorderedBox';
import { Tab } from 'components/Tabs/Tab';
import Tabs from 'components/Tabs';
import PricingTable from 'components/PricingTable';
import Checkbox from 'components/Checkbox';
import IndyLink from 'components/IndyLink';
import { Separator } from 'components/Separator';
import PriceLabel from 'components/PriceLabel';

import headerImg from 'media/pages/pricing/Package.png';
import AddOnsIcons from 'media/shared/modIcons/AddOns.inline.svg';
import { PagePicTitle } from 'components/PagePicTitle';

class PricingCalculatorPage extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {"includeTax": false,
                        "DD:taxRate": 0.2,
                        "DD:prices": [],
                        "DD:minDaySpend": null,
                        "DD:payThreshold": null};

        this.onChargeKeyChange = this.onChargeKeyChange.bind(this);
        this.handleTaxChange = this.handleTaxChange.bind(this);
    }

    componentDidMount(){
        this.listenerID = DataDriver.attachListenerForStateChange(this, ["prices", "taxRate", "chargeKeys", "minDaySpend", "payThreshold"]);
    }

    componentWillUnmount() {
        DataDriver.removeListener(this.listenerID);
    }

    handleTaxChange(event) {
        this.setState({"includeTax": event.target.checked});
    }

    onChargeKeyChange(chargeKey, Qty){
        let setState = {};
        setState[chargeKey] = Qty;
        this.setState(setState);
    }

    onProfileSelect(key){
        let newState = this.state;

        // Clear all current charge keys
        for (let i = 0; i < this.state["DD:chargeKeys"].length; i++) {
            newState[this.state["DD:chargeKeys"][i]] = undefined;
        }

        ViewTrack.send("/pricing/calculator", "ProfileSelected_"+key);

        // Set quantities
        switch (key) {
            case "PersonalFileStorage":
                newState["FileDownloadAmount"] = 0.06;
                newState["NumLibraryItems"] = 100;
                newState["LibraryNumberFiles"] = 800;
                break;

            case "SmallEngProj":
                newState["FileDownloadAmount"] = 0.1;
                newState["NumLibraryItems"] = 10;
                newState["LibraryNumberFiles"] = 100;
                newState["ActiveDesignItems"] = 500;
                newState["DormantDesignItems"] = 2000;
                break;

            case "ProjectManager":
                newState["FileDownloadAmount"] = 0.1;
                newState["NumLibraryItems"] = 50;
                newState["LibraryNumberFiles"] = 1000;
                newState["FilesScanned"] = 100;
                newState["ShareLinksSent"] = 1;
                newState["SharePortalsSent"] = 0.4;
                break;

            case "BigFileSender":
                newState["FileDownloadAmount"] = 1.5;
                newState["FilesScanned"] = 10;
                newState["ShareLinksSent"] = 5;
                newState["SharePortalsSent"] = 1;
                break;

            case "BigFileSender":
                newState["FileDownloadAmount"] = 1.5;
                newState["FilesScanned"] = 10;
                newState["ShareLinksSent"] = 5;
                newState["SharePortalsSent"] = 1;
                break;
        
            default:
                break;
        }

        this.setState(newState);
    }

    render() {
        // Calculate usage
        let totPrice = new Decimal(0);
        statekey1:
        for (const testChargeKey in this.state["DD:prices"]) {
            if (!this.state["DD:prices"].hasOwnProperty(testChargeKey)) {
                continue;
            }

            for (const chargeKey in this.state) {
                if (!(this.state.hasOwnProperty(chargeKey) && testChargeKey === chargeKey && Utils.isNumeric(this.state[chargeKey]))) {
                    continue;
                }

                if (this.state["DD:prices"][testChargeKey].PriceModel != "PercOnTotal"){
                    const qty = new Decimal(this.state[chargeKey]);
                    const priceDat = new Decimal(this.state["DD:prices"][testChargeKey].currentPrice);

                    let price = priceDat.mul(qty);

                    if (this.state.includeTax){
                        price = price.add(price.mul(this.state["DD:taxRate"]));
                    }

                    totPrice = totPrice.add(price);
                }

                continue statekey1;
            }
        }

        // Convert to monthly price
        let totMonthPrice = totPrice.mul(30);

        // Store usage to send to pricing tables
        let totMonthUsage = totMonthPrice;

        // Add percentage on total usage
        statekey2:
        for (const testChargeKey in this.state["DD:prices"]) {
            if (!this.state["DD:prices"].hasOwnProperty(testChargeKey)) {
                continue;
            }

            for (const chargeKey in this.state) {
                if (this.state.hasOwnProperty(chargeKey) && 
                    testChargeKey === chargeKey && 
                    Utils.isNumeric(this.state[chargeKey]) &&
                    this.state["DD:prices"][testChargeKey].PriceModel == "PercOnTotal") 
                {
                        let addition = totMonthPrice.mul(this.state["DD:prices"][testChargeKey].currentPrice).div(100).mul(this.state[chargeKey]);
                        totMonthPrice = totMonthPrice.add(addition);
                        continue statekey2;
                }
            }
        }

        // Work out if under charge threshold
        let isUnderThresh;
        let thresh;
        if (this.state["DD:payThreshold"] !== null){
            thresh = new Decimal(this.state["DD:payThreshold"]);

            if (this.state.includeTax){
                thresh = thresh.add(thresh.mul(this.state["DD:taxRate"]));
            }

            isUnderThresh = totMonthPrice.lessThan(thresh);
            if (isUnderThresh){
                totMonthPrice = new Decimal(0);
            }
        }else{
            thresh = new Decimal("0");
            isUnderThresh = true; // Starts off true
        }

        return (
            <PageWrap path="/pricing/calculator" title="Pricing Calculator - Mashoom">
                <PagePicTitle
                    id="PricesTop"
                    imageSrc={headerImg}
                    imageAlt="Mock Mashoom physical package"
                >
                    <BorderedBox>
                        <>
                            <h1>Pricing Calculator</h1>
                            <p>Estimate the cost of your solution by using our calculator</p>
                            <p className='paraSpaceTop'>Each of our modules have some metrics that capture how much you use them. Fill in your estimated usage below to get an idea of your monthly bill.</p>
                        </>
                    </BorderedBox>
                </PagePicTitle>
                <Section id="AggregateTotalWrap">
                    <div id="AggregateTotal">
                        {totMonthPrice.isZero() ? (
                            <p id="AggregateTotalValue">Free</p>
                        ):(
                            <p id="AggregateTotalValue">£{totMonthPrice.toFixed(2)} a month</p>
                        )}
                        {isUnderThresh &&
                            <p id="AggregateTotalUnderThresh">The selected usage is under our £{thresh.toFixed(2)}/month threshold</p>
                        }
                        <p id="AggregateTotalDescr">
                            Based on the daily price times by 30,
                            {this.state.includeTax ? (
                                " including VAT"
                            ) : (
                                " excluding VAT"
                            )}
                            .
                        </p>
                    </div>
                </Section>
                <div id="taxIncludeWrap">
                    <div>
                        <p>Mashoom is free for educational or non-profit teams, we also can provide negotiated fixed pricing, <IndyLink adr="/about">please get in contact</IndyLink>.</p>
                    </div>
                    <div>
                        <Checkbox
                            label="Include VAT"
                            isChecked={this.state.includeTax}
                            changeHandler={this.handleTaxChange}
                        />
                    </div>
                </div>
                <Section id="PricesTabs" margin="none">
                    <Tabs>
                        <Tab
                            tabTitle="General"
                        >
                            <h3 className="secndPriceTitle">General</h3>
                            <PricingTable 
                                module="General"
                                chargeKeyQts={this.state}
                                usageTotal={totMonthUsage}
                                qtyChange={this.onChargeKeyChange}
                                includeTax={this.state.includeTax}
                            />
                        </Tab>
                        <Tab
                            tabTitle="Library"
                            tabModuleIcon="Library"
                        >
                            <h3 className="secndPriceTitle">Library</h3>
                            <PricingTable 
                                module="Library"
                                chargeKeyQts={this.state}
                                usageTotal={totMonthUsage}
                                qtyChange={this.onChargeKeyChange}
                                includeTax={this.state.includeTax}
                            />
                        </Tab>
                        <Tab
                            tabTitle="Share"
                            tabModuleIcon="Share"
                        >
                            <h3 className="secndPriceTitle">Share</h3>
                            <PricingTable 
                                module="Share"
                                chargeKeyQts={this.state}
                                usageTotal={totMonthUsage}
                                qtyChange={this.onChargeKeyChange}
                                includeTax={this.state.includeTax} 
                            />
                        </Tab>
                        <Tab
                            tabTitle="Contact"
                            tabModuleIcon="Contact"
                        >
                            <h3 className="secndPriceTitle">Contact</h3>
                            <PricingTable 
                                module="Contact"
                                chargeKeyQts={this.state}
                                usageTotal={totMonthUsage}
                                qtyChange={this.onChargeKeyChange}
                                includeTax={this.state.includeTax} 
                            />
                        </Tab>
                        <Tab
                            tabTitle="Quote"
                            tabModuleIcon="Quote"
                        >
                            <h3 className="secndPriceTitle">Quote</h3>
                            <PricingTable 
                                module="Quote"
                                chargeKeyQts={this.state}
                                usageTotal={totMonthUsage}
                                qtyChange={this.onChargeKeyChange}
                                includeTax={this.state.includeTax} 
                            />
                        </Tab>
                        <Tab
                            tabTitle="Forms"
                            tabModuleIcon="Forms"
                        >
                            <h3 className="secndPriceTitle">Forms</h3>
                            <PricingTable 
                                module="Form"
                                chargeKeyQts={this.state}
                                usageTotal={totMonthUsage}
                                qtyChange={this.onChargeKeyChange}
                                includeTax={this.state.includeTax} 
                            />
                        </Tab>
                        <Tab
                            tabTitle="Physical Things"
                            tabModuleIcon="GenPhl"
                        >
                            <h3 className="secndPriceTitle">Physical Things</h3>
                            <PricingTable 
                                module="GenPhl"
                                chargeKeyQts={this.state}
                                usageTotal={totMonthUsage}
                                qtyChange={this.onChargeKeyChange}
                                includeTax={this.state.includeTax} 
                            />
                        </Tab>
                        <Tab
                            tabTitle="Booking"
                            tabModuleIcon="Locations"
                        >
                            <h3 className="secndPriceTitle">Booking</h3>
                            <PricingTable 
                                module="Location"
                                chargeKeyQts={this.state}
                                usageTotal={totMonthUsage}
                                qtyChange={this.onChargeKeyChange}
                                includeTax={this.state.includeTax} 
                            />
                        </Tab>
                        <Tab
                            tabTitle="Design"
                            tabModuleIcon="Design"
                        >
                            <h3 className="secndPriceTitle">Design</h3>
                            <PricingTable 
                                module="Design"
                                chargeKeyQts={this.state}
                                usageTotal={totMonthUsage}
                                qtyChange={this.onChargeKeyChange}
                                includeTax={this.state.includeTax}
                            />
                        </Tab>
                        <Tab
                            tabTitle="Add-ons"
                            tabImage={AddOnsIcons}
                            tabImageAlt="Add-ons icon"
                        >
                            <h3 className="secndPriceTitle">Add-ons</h3>
                            <PricingTable 
                                module="Add-ons"
                                chargeKeyQts={this.state}
                                usageTotal={totMonthUsage}
                                qtyChange={this.onChargeKeyChange}
                                includeTax={this.state.includeTax}
                            />
                        </Tab>
                    </Tabs>
                </Section>
                <Separator margin="TOP"/>
                <Section id="PriceDetails" margin="none">
                    <BorderedBox>
                        <div className="PDCont">
                            <h2>A few things to note...</h2>
                            <ul>
                                <li>There is a <PriceLabel chargeKey="payThresh" includeTax={this.state.includeTax} /> payment threshold; only if you exceed this will you be charged</li>
                                <li>Payment is taken each month</li>
                                <li>We accept card payments. Once payment details have been entered we automatically take payment</li>
                                <li>If payment fails we will notify you and provide a 2 week period to settle the invoice</li>
                                <li>Invoice payments and fixed pricing can be negotiated, <IndyLink adr="/about/#ContactUs">get in contact</IndyLink></li>
                            </ul>
                        </div>
                    </BorderedBox>
                </Section>
            </PageWrap>
        );
    }
}

export default PricingCalculatorPage;

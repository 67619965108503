import React from 'react';

import './style.scss';

import { ModuleIcon } from 'components/ModuleIcon';

class Tabs extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = { 
            "tabInd": 0
        }

        this.changeTab = this.changeTab.bind(this);
    }

    changeTab(ind){
        this.setState({
            "tabInd": ind
        });
    }

    render() {
        return (
            <div className="tabsContainer">
                <div className="tabsSelect">
                    {React.Children.map(this.props.children, (child, i) => (
                        <div key={i} 
                            className={"tabSelect" + 
                                        (i === this.state.tabInd ? " active" : "") + 
                                        (!child.props.tabModuleIcon && !child.props.tabImage ? " noImg" : "")} 
                            onClick={this.changeTab.bind(this, i)}    
                        >
                            {child.props.tabModuleIcon && 
                                <ModuleIcon name={child.props.tabModuleIcon} />
                            }
                            {child.props.tabImage && 
                                <div className="tabSelectImgWrap">
                                    <child.props.tabImage alt={child.props.tabImageAlt} />
                                </div>
                            }
                            {child.props.tabTitle && 
                                <div className="tabSelectTitleWrap">
                                    <h3>{child.props.tabTitle}</h3>
                                </div>
                            }
                        </div>
                    ))}
                </div>
                <div className="tabsContent">
                    {React.Children.map(this.props.children, (child, i) => (
                        <div key={i} 
                            className={"tabContent" + (i === this.state.tabInd ? " active" : "")}>
                            {child.props.children}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default Tabs;
